import React from "react";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ClientPage from "./Page/ClientPage";
import Footer from "./Footer";
import Header from "./Header";
import CloseIcon from "@mui/icons-material/Close";

const Page = () => {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ display: "flex" }}>
      {/* Header */}
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>
          <Header />
        </Toolbar>
      </AppBar>
      {/* Side Menu */}
      <Drawer variant="persistent" anchor="left" open={open}>
        <List>
          <ListItem onClick={handleDrawerClose}>
            <ListItemText primary="Option 1" />
          </ListItem>
          <ListItem onClick={handleDrawerClose}>
            <ListItemText primary="Option 2" />
          </ListItem>
          <IconButton onClick={handleDrawerClose}>
            <CloseIcon />
          </IconButton>
          {/* Ajoutez d'autres options de menu ici */}
        </List>
      </Drawer>
      <ClientPage />

      <Footer />
    </div>
  );
};

export default Page;
