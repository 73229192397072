import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { IClient } from '../../Class/clients';

interface TableProps {
  items: IClient[];
}

const TTable: React.FC<TableProps> = ({ items }) => {
  return (
    <Table>
    <TableHead>
      <TableRow>
        <TableCell>ID</TableCell>
        <TableCell>Nom</TableCell>
        <TableCell>Description</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {items.map((item) => (
        <TableRow key={item.id}>
          <TableCell>{item.id}</TableCell>
          <TableCell>{item.nom}</TableCell>
          <TableCell>{item.description}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
  );
};

export default TTable;