import React from 'react';
import Typography from '@mui/material/Typography';
import { AppBar, Toolbar } from '@mui/material';

const Footer = () => {
  return (
    <AppBar position="fixed" style={{ top: 'auto', bottom: 0 }}>
        <Toolbar>
          <Typography variant="body1" align="center" style={{ width: '100%' }}>
            Mon pied de page
          </Typography>
        </Toolbar>
      </AppBar>
  );
};

export default Footer;
