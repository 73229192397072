import Typography from "@mui/material/Typography";
import { Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import LogoutIcon from "@mui/icons-material/Logout";

const Header = () => {
  return (
    <>
      <Typography variant="h6" noWrap>
      Maxime Bodivit Vision
      </Typography>
      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
        SoftWare 2024
      </Typography>
      <Fab size="small" variant="extended" aria-label="like">
        <AddIcon />
        Ajouter un client
      </Fab>
      <Fab size="small" variant="extended" aria-label="like">
        <LogoutIcon />
        Se déconnecter
      </Fab>
    </>
  );
};

export default Header;
