import React, { useState, useEffect } from 'react';
import { IClient } from '../../Class/clients';
import { Button } from '@mui/material';
import TTable from './Table';


const ClientPage: React.FC = () => {
  const [clients, setClients] = useState<IClient[]>([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch('http://localhost:5127/clients/liste');
      const data = await response.json();
      setClients(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  const saveItems = async () => {
    try {
      await fetch('http://localhost:5127/clients/save', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(clients),
      });
      console.log('Data saved successfully');
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  const reloadTable = () => {
    fetchData();
  };

  const addItem = () => {
    const newItem: IClient = { 
      id: clients.length + 1, 
      nom: `Item ${clients.length + 1}`,
      description: `Decr ${clients.length + 1}`
     };
    setClients([...clients, newItem]);
  };

  return (
    <div style={{ flexGrow: 1, marginTop: 64, padding: 20 }}>
      <h1>Clients</h1>
      <Button variant="contained" onClick={addItem} >Ajouter un item</Button>
      <Button variant="contained" onClick={saveItems}>Enregistrer</Button>
      <Button variant="contained" onClick={reloadTable}>Recharger le tableau</Button>
      <TTable items={clients} />
    </div>
  );
};

export default ClientPage;
